.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*.header {*/
/*  background-image: url(//cdn.shopify.com/s/files/1/0588/8779/8949/files/Slabs-Mock2-1920x1440_1920x.jpg?v=1634932651);*/
/*  position: relative;*/
/*  background-repeat: no-repeat;*/
/*  background-size: cover;*/
/*  background-position: center;*/
/*  z-index: 99;*/
/*  padding-top: 55px;*/
/*  overflow: hidden;*/
/*  height: 100vh;*/
/*}*/

.navbar * {
  font-size: 15px;
  font-family: 'oswald', 'HelveticaNeue', 'Helvetica Neue', 'Helvetica-Neue', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.content {
  padding-top: 100px;
  padding-bottom: 100px;
}

.product-image {
  max-width: 200px;
  max-height: 300px;
}

.product-image-large {
  max-width: 350px;
  max-height: 500px;
}

.table-layout-fixed {
  table-layout: fixed;
}

.card-report-info-table td:first-child {
  width: 175px;
  font-weight: bold;
  text-align: right;
}

.grading-frame {
  background: rgba(255,255,255,.7);
  border-radius: 10px;
}

.grade-final {
  line-height: 0.8;
}

.grade-final-text {
  font-size: .6em;
}

.bg-silver {
  background-color: #868DA7;
}

.text-gold {
  color: #DEBB68;
}

.text-bronze {
  color: #977539;
}

.background {
  background-image: url("../assets/background.jpg");
  background-position: top center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: -1;
}

body {
  background-color: rgba(58,58,58,0.9);
}